<template>
  <CommonLayout class="catalog-layout">
    <!-- <Balance :points="this.getProfile.coinBalance" /> -->
    <div class="catalog">
      <div class="catalog__container">
        <div class="catalog__header">
          <!-- <router-link
            class="button-default button-back catalog__back"
            tag="button"
            :to="{ name: 'home' }"
            ><span></span>Назад</router-link
          > -->
          <div class="catalog__group">
            <h1 class="title">
              Виртуальный магазин
              <div class="catalog-balance">
                <img
                  src="@/assets/img/pic2/coin.svg"
                  width="22"
                  height="22"
                  alt=""
                />
                GPB-coins:<b>{{ getProfile.coinBalance }}</b>
              </div>
            </h1>
            <router-link
              class="button button-default catalog-order"
              tag="button"
              :to="{ name: 'orders' }"
              ><span>Мои заказы</span></router-link
            >
          </div>
        </div>
        <div class="col__filter">
          <div class="col col__filter-text">
            <div class="text">Сортировать по цене:</div>
          </div>
          <div class="col col__filter-type">
            <v-select
              class="select-custom"
              v-model="value"
              :clearable="false"
              :searchable="false"
              :options="optionsType"
            ></v-select>
          </div>
        </div>
        <Loading v-if="loading" position="center" theme="dark" />

        <div class="catalog__items">
          <div
            v-for="(item, index) in sortCatalog"
            :key="index"
            tag="div"
            class="catalog__item catalog-item"
          >
            <router-link
              class="catalog-item__link"
              :to="`/store/${item.id}`"
            ></router-link>
            <div
              class="catalog-item__image"
              @click="$modal.show('photo', { src: image(item) })"
            >
              <img :src="image(item)" width="140" height="140" alt="" />
            </div>
            <div class="catalog-item__content">
              <div class="catalog-item__title">{{ item.name }}</div>
              <div class="catalog-item__desc">{{ item.description }}</div>
            </div>
            <div class="catalog-item__price">
              <span>{{ item.price | priceFilter }}</span>
              <div class="catalog-item__coins">
                <span class="coin">
                  <img
                    src="@/assets/img/pic2/coin.svg"
                    width="15"
                    height="15"
                    alt=""
                  />
                </span>
                {{ COINS }}
              </div>
            </div>
            <div>
              <div class="catalog-item__actions">
                <!-- <button
                  :disabled="lock"
                  class="button button-default catalog-item__button"
                  @click.stop="addToCart(item)"
                >
                  Заказать
                </button> -->
                <router-link
                  class="button button-default catalog-item__button"
                  :to="`/store/${item.id}`"
                  >Заказать</router-link
                >
                <div class="catalog-item__actions-text">
                  Доступно: {{ item.amount }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<script>
import CommonLayout from "@/layout/CommonLayout.vue";
// import Balance from "@/components/DashboardBalance.vue";
import { mapGetters, mapActions } from "vuex";
import { isCartFull } from "@/utils/store";
import { getBaseURL } from "@/utils";

const optionsType = [{ label: "Сначала дешевле" }, { label: "Сначала дороже" }];

export default {
  components: {
    CommonLayout,
    // Balance,
  },
  computed: {
    ...mapGetters([
      "getCatalogStatus",
      "getCatalog",
      "getCart",
      "getProfile",
      "getGame",
    ]),
    loading() {
      return this.getCatalogStatus === "loading";
    },
    beforeImage() {
      return getBaseURL();
    },
    sortCatalog() {
      return this.getCatalog
        .map((e) => e)
        .sort((a, b) => {
          if (this.value.label === "Сначала дешевле") {
            return a.price - b.price;
          } else if (this.value.label === "Сначала дороже") {
            return b.price - a.price;
          }
          return;
        });
    },
  },
  created() {
    if (this.getCatalogStatus !== "success") {
      this.CATALOG_FETCH();
    }
  },
  data() {
    return {
      lock: false,
      optionsType,
      value: { code: "0", label: "Сначала дешевле" },
    };
  },
  methods: {
    ...mapActions([
      "CATALOG_FETCH",
      "CART_ADD",
      "CART_CHECKOUT",
      "USER_FETCH",
      "USER_FETCH_NOTIFY",
    ]),
    buttonPressed(id) {
      let status = false;

      this.getCart.forEach((product) => {
        if (product.id === id) {
          status = true;
        }
      });

      return status;
    },
    checkShopOpen() {
      if (this.getGame.currentWeek >= 2) {
        return true;
      }
      return false;
    },
    addToCart(product) {
      if (!this.checkShopOpen()) {
        this.$modal.show("message", {
          message: "Магазин откроется с 3 недели",
        });
        return;
      }
      if (product.amount <= 0) {
        this.$modal.show("message", {
          message: `Извините, на Обливии закончился трофей "${product.name}". Выберите другой подарок.`,
        });
        return;
      }

      // if (this.buttonPressed(product.id)) {
      //   this.$router.push({ name: "cart" });
      //   return;
      // }

      this.$modal.show("confirm", {
        message: "Готовы подтвердить заказ?",
        cb: () => {
          if (isCartFull(product, 1, this.getCart)) {
            this.CART_ADD({
              data: product,
              count: 1,
            }).then(() => {
              this.checkout();
            });
          }
        },
      });
    },
    image(item) {
      if (item.imgUrl) {
        return `${getBaseURL()}${item.imgUrl}`;
      }
      return `/static/img/photo-default.svg`;
    },
    checkout() {
      const {
        comment,
        getCart,
        getProfile: { id: userId },
      } = this;

      const orderDetails = getCart.map(({ id, prizeAmount, price }) => {
        return {
          prizeId: id,
          prizeAmount,
          prizePrice: price,
        };
      });

      this.lock = true;

      this.CART_CHECKOUT({
        userId,
        orderDetails,
        comment,
      })
        .then(() => {
          this.$modal.show("message", {
            message: "Заказ успешно оформлен!",
          });
          this.USER_FETCH();
          this.$router.push({ name: "orders" });
          this.USER_FETCH_NOTIFY();
          this.lock = false;
          setTimeout(() => {
            this.CATALOG_FETCH();
          }, 1000);
        })
        .catch((error) => {
          this.lock = false;
          this.handleError(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.catalog {
  &__container {
    position: relative;
    z-index: 2;
    width: 1230px;
    margin: 0 auto;
  }
  &__header {
    position: relative;
    margin-bottom: 20px;
    font-size: 54px;
    color: #5cffed;
  }
  &__back {
    position: absolute;
    right: 100%;
    top: 10px;
    margin-right: 20px;
    @media (max-width: 1700px) {
      display: none;
    }
    //   top: auto;
    //   bottom: 100%;
    //   right: auto;
    //   left: 0;
    //   margin-bottom: 30px;
    // }
  }
  &__group {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    font-weight: normal;
    font-size: 40px;
  }
  &__item {
    position: relative;
    &:not(:last-child) {
      margin-bottom: 29px;
    }
  }
  &-order {
    margin-left: 20px;
    position: relative;
    margin-bottom: -18px;
  }
  &-balance {
    height: 45px;
    margin-left: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 32px;
    color: #fff;
    background-image: url(~@/assets/img/icon/bracket.svg);
    background-repeat: no-repeat;
    position: relative;
    padding: 0 15px;
    img {
      margin-right: 4px;
      display: block;
      margin-bottom: -6px;
    }
    b {
      font-weight: 700;
      font-size: 32px;
      color: #00ffff;
      margin-left: 8px;
    }
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      height: 45px;
      width: 12px;
      background-image: url(~@/assets/img/icon/bracket.svg);
      background-repeat: no-repeat;
      transform: rotate(180deg);
      content: "";
    }
  }
}

.catalog-item {
  @include clickable();
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 140px 1fr 245px 200px;
  align-items: center;

  background: url("~@/assets/img/pic2/divider-table.svg") 50% 100% no-repeat;
  background-color: rgba(0, 255, 254, 0.15);
  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__image {
    position: relative;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='137' viewBox='0 0 13 137' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-.4357 137h12.871v-4.5h-8.43V5h8.43V0H-.4357v137z' fill='%230FF'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: auto 100%;
      content: "";
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='137' viewBox='0 0 13 137' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-.4357 137h12.871v-4.5h-8.43V5h8.43V0H-.4357v137z' fill='%230FF'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: auto 100%;
      content: "";
      transform: rotate(180deg);
    }
    img {
      object-fit: cover;
      object-position: center;
      display: block;
    }
  }
  &__content {
    padding-left: 40px;
    padding-right: 40px;
  }
  &__title {
    font-family: $fontRafale;
    font-size: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }
  &__desc {
    font-family: $fontRafale;
    font-size: 21px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 5px;
    word-break: break-word;
  }
  &__price {
    display: flex;
    justify-content: flex-end;
    margin-right: 40px;
    font-size: 24px;
    color: #50ffeb;
    span {
    }
  }
  &__coins {
    display: flex;
    margin-left: 5px;
    white-space: nowrap;
    .coin {
      margin-right: 20px;
    }
  }
  &__actions {
    display: inline-block;
    text-align: center;
    padding-top: 26px;
    &-text {
      margin-top: 10px;
      font-family: $fontRafale;
      font-size: 14px;
    }
  }

  &__button {
    min-width: 150px;
  }
}

.col {
  display: inline-flex;
  &__filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
  }
  &__filter-text {
    text-align: right;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;

    color: #ade5d9;
  }
  &__filter-type {
    margin-left: 10px;
  }
}
.text {
  font-family: $fontRafale;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  &__text-admin {
    text-decoration-line: underline;
  }
  &__text-table-message {
    font-family: $fontRafale;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    padding: 10px 0 15px 30px;
  }
}

@media (max-width: 1750px) {
  .catalog__decor {
    right: 15px;
  }
}
</style>
